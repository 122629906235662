import React from "react";

import { EditFeaturesAndAmenitiesView } from "./view";
import { EditFacilityDialog } from "@pages/DeepDivePanel/EditFacilityDialog";
import { useDeepDivePanelStore } from "@hooks/useDeepDivePanelStore";

const EditFeaturesAndAmenities: React.FC = () => {
  const [{ facility }] = useDeepDivePanelStore();

  return (
    <EditFacilityDialog
      name="EditFeaturesAndAmenities"
      title="Features & amenities"
      viewPath={`/map/${facility.id}/features-and-amenities`}
    >
      {(view, setView) => (
        <EditFeaturesAndAmenitiesView
          facilityView={view}
          setFacilityView={setView}
        />
      )}
    </EditFacilityDialog>
  );
};

export default EditFeaturesAndAmenities;
