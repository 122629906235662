import keyBy from "lodash/keyBy";

import { Models } from "@services/api";

export type Feature = {
  id: keyof Models.FacilityFeaturesInfo;
  name: string;
};

export const features: Feature[] = [
  {
    id: "pottyTraining",
    name: "Potty training",
  },
  {
    id: "strollerStorage",
    name: "Stroller or car seat storage",
  },
  {
    id: "creditCards",
    name: "Credit cards",
  },
  {
    id: "playground",
    name: "Outdoor play",
  },
  {
    id: "parkingLot",
    name: "Parking lot",
  },
  {
    id: "busService",
    name: "Bus service",
  },
  {
    id: "uniforms",
    name: "Uniforms",
  },
  {
    id: "naptime",
    name: "Naptime",
  },
];

export const servicesMap = keyBy(features, "id");
