import React from "react";
import clsx from "clsx";
import { useFormContext, Controller } from "react-hook-form";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import isNil from "lodash/isNil";

import { OutlinedInput } from "@ui-kit/InputFields";
import { useCss } from "./styles";
import Checkbox from "@material-ui/core/Checkbox";
import { servicesMap } from "@constants/school-features";
import Typography from "@ui-kit/Typography";
import { Models } from "@services/api";
import { Spacer } from "@ui-kit/Spacer";

interface IProps {
  facilityView: Models.Facility;
  setFacilityView: React.Dispatch<React.SetStateAction<Models.Facility>>;
  isClaim?: boolean;
}

export const EditFeaturesAndAmenitiesView: React.FC<IProps> = ({
  facilityView,
  setFacilityView,
  isClaim,
}) => {
  const { control, errors } = useFormContext();
  const css = useCss();

  const getRadioItem = (id: keyof Models.FacilityFeaturesInfo) => (
    <FormControl
      key={`feature-${id}`}
      error={!!errors[id]?.message}
      css={css.radioContainer}
      className={clsx({
        "form-error": !!errors[id]?.message,
      })}
    >
      <FormLabel css={css.radioLabel}>
        <Typography variant="inherit" required={isClaim}>
          {servicesMap[id].name}
        </Typography>
      </FormLabel>
      <Controller
        render={({ onChange, ...controllerProps }) => (
          <RadioGroup
            {...controllerProps}
            css={css.group}
            data-test={`service-${id}`}
            onChange={(event) => {
              event.persist();
              setFacilityView((prev) => ({
                ...prev,
                featuresInfo: {
                  ...prev.featuresInfo,
                  [id]: event.target.value === "yes",
                },
              }));

              onChange(event);
            }}
          >
            <FormControlLabel
              value="yes"
              control={<Radio color="primary" css={css.radio} />}
              label="Yes"
              data-test={`${id}-feature-radio-yes`}
            />
            <FormControlLabel
              value="no"
              control={<Radio color="primary" css={css.radio} />}
              label="No"
              data-test={`${id}-feature-radio-no`}
            />
          </RadioGroup>
        )}
        rules={{
          validate: (value: string) => {
            if (isClaim && !value) {
              return "Cannot be blank";
            }

            return true;
          },
        }}
        defaultValue={
          isNil(facilityView.featuresInfo?.[id])
            ? ""
            : facilityView.featuresInfo?.[id]
            ? "yes"
            : "no"
        }
        name={id}
        control={control}
      />
    </FormControl>
  );

  return (
    <div data-test="facility-operating-details-editor">
      <div
        css={css.checkRow}
        onClick={() => {
          setFacilityView((prev) => ({
            ...prev,
            featuresInfo: {
              ...prev.featuresInfo,
              mobileParentApp:
                !isNil(prev.featuresInfo?.mobileParentApp) &&
                prev.featuresInfo?.mobileParentApp !== Models.MobileParentApp.No
                  ? Models.MobileParentApp.No
                  : Models.MobileParentApp.Yes,
            },
          }));
        }}
        data-test="mobile-parent-app-check"
      >
        <Checkbox
          color="primary"
          checked={
            !isNil(facilityView.featuresInfo?.mobileParentApp) &&
            facilityView.featuresInfo?.mobileParentApp !==
              Models.MobileParentApp.No
          }
        />
        <Typography>Mobile parent app</Typography>
      </div>

      {!isNil(facilityView.featuresInfo?.mobileParentApp) &&
        facilityView.featuresInfo?.mobileParentApp !==
          Models.MobileParentApp.No && (
          <RadioGroup
            name="mobileParentApp"
            css={css.subradioGroup}
            value={facilityView.featuresInfo.mobileParentApp}
          >
            <FormControlLabel
              value={Models.MobileParentApp.BrightWheel}
              data-test="mobile-app-BrightWheel"
              control={
                <Radio
                  color="primary"
                  onClick={() => {
                    setFacilityView((prev) => ({
                      ...prev,
                      featuresInfo: {
                        ...prev.featuresInfo,
                        mobileParentApp:
                          prev.featuresInfo.mobileParentApp ===
                          Models.MobileParentApp.BrightWheel
                            ? Models.MobileParentApp.Yes
                            : Models.MobileParentApp.BrightWheel,
                      },
                    }));
                  }}
                />
              }
              label="BrightWheel"
            />
            <FormControlLabel
              value={Models.MobileParentApp.ClassDojo}
              data-test="mobile-app-ClassDojo"
              control={
                <Radio
                  color="primary"
                  onClick={() => {
                    setFacilityView((prev) => ({
                      ...prev,
                      featuresInfo: {
                        ...prev.featuresInfo,
                        mobileParentApp:
                          prev.featuresInfo.mobileParentApp ===
                          Models.MobileParentApp.ClassDojo
                            ? Models.MobileParentApp.Yes
                            : Models.MobileParentApp.ClassDojo,
                      },
                    }));
                  }}
                />
              }
              label="ClassDojo"
            />
            <FormControlLabel
              value={Models.MobileParentApp.Tadpoles}
              data-test="mobile-app-Tadpoles"
              control={
                <Radio
                  color="primary"
                  onClick={() => {
                    setFacilityView((prev) => ({
                      ...prev,
                      featuresInfo: {
                        ...prev.featuresInfo,
                        mobileParentApp:
                          prev.featuresInfo.mobileParentApp ===
                          Models.MobileParentApp.Tadpoles
                            ? Models.MobileParentApp.Yes
                            : Models.MobileParentApp.Tadpoles,
                      },
                    }));
                  }}
                />
              }
              label="Tadpoles"
            />
          </RadioGroup>
        )}

      <div
        css={css.checkRow}
        onClick={() => {
          setFacilityView((prev) => ({
            ...prev,
            featuresInfo: {
              ...prev.featuresInfo,
              videoStreaming:
                !isNil(prev.featuresInfo.videoStreaming) &&
                prev.featuresInfo.videoStreaming !== Models.VideoStreaming.No
                  ? Models.VideoStreaming.No
                  : Models.VideoStreaming.Yes,
            },
          }));
        }}
        data-test="video-streaming-app-check"
      >
        <Checkbox
          color="primary"
          checked={
            !isNil(facilityView.featuresInfo.videoStreaming) &&
            facilityView.featuresInfo.videoStreaming !==
              Models.VideoStreaming.No
          }
        />
        <Typography>Video streaming app</Typography>
      </div>

      {!isNil(facilityView.featuresInfo.videoStreaming) &&
        facilityView.featuresInfo.videoStreaming !==
          Models.VideoStreaming.No && (
          <RadioGroup
            name="videoStreaming"
            css={css.subradioGroup}
            value={facilityView.featuresInfo.videoStreaming}
          >
            <FormControlLabel
              value={Models.VideoStreaming.WatchMeGrow}
              data-test="mobile-app-WatchMeGrow"
              control={
                <Radio
                  color="primary"
                  onClick={() => {
                    setFacilityView((prev) => ({
                      ...prev,
                      featuresInfo: {
                        ...prev.featuresInfo,
                        videoStreaming:
                          prev.featuresInfo.videoStreaming ===
                          Models.VideoStreaming.WatchMeGrow
                            ? Models.VideoStreaming.Yes
                            : Models.VideoStreaming.WatchMeGrow,
                      },
                    }));
                  }}
                />
              }
              label="WatchMeGrow"
            />
            <FormControlLabel
              value={Models.VideoStreaming.PbAndJ}
              data-test="mobile-app-PbAndJ"
              control={
                <Radio
                  color="primary"
                  onClick={() => {
                    setFacilityView((prev) => ({
                      ...prev,
                      featuresInfo: {
                        ...prev.featuresInfo,
                        videoStreaming:
                          prev.featuresInfo.videoStreaming ===
                          Models.VideoStreaming.PbAndJ
                            ? Models.VideoStreaming.Yes
                            : Models.VideoStreaming.PbAndJ,
                      },
                    }));
                  }}
                />
              }
              label="PB&J"
            />
          </RadioGroup>
        )}

      <Spacer size="medium" />

      {getRadioItem("creditCards")}
      {getRadioItem("strollerStorage")}
      {getRadioItem("playground")}

      <Spacer size="medium" />

      {getRadioItem("parkingLot")}
      {getRadioItem("uniforms")}
      {getRadioItem("busService")}

      <Spacer size="medium" />

      {getRadioItem("pottyTraining")}
      <OutlinedInput
        value={facilityView.featuresInfo.pottyTrainingDescription || ""}
        onChange={(event) => {
          setFacilityView((prev) => ({
            ...prev,
            featuresInfo: {
              ...prev.featuresInfo,
              pottyTrainingDescription: event.target.value,
            },
          }));
        }}
        multiline
        placeholder="PottyTraining"
        rowsMax="6"
        maxChars={750}
        name="pottyTrainingDescription"
      />

      <Spacer size="small" />

      {getRadioItem("naptime")}
      <OutlinedInput
        value={facilityView.featuresInfo.naptimeDescription}
        onChange={(event) => {
          setFacilityView((prev) => ({
            ...prev,
            featuresInfo: {
              ...prev.featuresInfo,
              naptimeDescription: event.target.value,
            },
          }));
        }}
        multiline
        placeholder="Describe naptime routine"
        rowsMax="6"
        name="naptimeDescription"
        maxChars={750}
      />
    </div>
  );
};
